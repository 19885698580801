import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Synology and Full HD Cameras",
  "path": "/Frequently_Asked_Question/Synology_and_Full_HD_Cameras/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "Does anyone have experience with the in-8015hd and the synology surveillance station?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - Synology and Full HD Cameras' dateChanged='2018-03-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='Does anyone have experience with the in-8015hd and the synology surveillance station?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Synology_and_Full_HD_Cameras/' locationFR='/fr/Frequently_Asked_Question/Synology_and_Full_HD_Cameras/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <p><strong parentName="p">{`UPDATE: Full HD cameras IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD are now fully supported`}</strong></p>
    <p><strong parentName="p">{`Q`}</strong>{`: Does anyone have experience with the in-8015hd and the synology surveillance station?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Both Synology and QNAP will add the 1080p models in an upcoming update. But you can already insert the IN-8015 HD in the Surveillance Station. Ignore the error message during testing and just keep pressing:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "804px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/60398/QNAP_Model-Mismatch.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHro2kxSH//xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIRAxL/2gAIAQEAAQUCLbUzXRrT0af/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAABBQAAAAAAAAAAAAAAAAAAAiAhIjH/2gAIAQEABj8CK6Sln//EABsQAQEAAgMBAAAAAAAAAAAAAAEAQXERITFR/9oACAEBAAE/IepCgcC8xuo44lfLW//aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxIUH/2gAIAQEAAT8QoHlwD2cLckQpAs8yJQV+3KIiYx//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/e4706/QNAP_Model-Mismatch.avif 230w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/d1af7/QNAP_Model-Mismatch.avif 460w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/4ee0d/QNAP_Model-Mismatch.avif 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/a0b58/QNAP_Model-Mismatch.webp 230w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/bc10c/QNAP_Model-Mismatch.webp 460w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/95e88/QNAP_Model-Mismatch.webp 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/e83b4/QNAP_Model-Mismatch.jpg 230w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/e41a8/QNAP_Model-Mismatch.jpg 460w", "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/60398/QNAP_Model-Mismatch.jpg 804w"],
              "sizes": "(max-width: 804px) 100vw, 804px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ea9992e02b5d5cd453eb97976cf9ce5d/60398/QNAP_Model-Mismatch.jpg",
              "alt": "QNAP Model Mismatch",
              "title": "QNAP Model Mismatch",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After that choose 1280x720 resolution as video resolution - since the same CGI commands are used here as with the IN-6014HD, the recording will then take place in the highest resolution of 1920x1080.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "604px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/efa6e/QNAP_FullHD-as-HD.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe2ugmP/xAAWEAEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQEAAQUCIiN//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAIDH/2gAIAQEABj8Cq//EABoQAAICAwAAAAAAAAAAAAAAAAABEDERUYH/2gAIAQEAAT8hwYQSaL2dj//aAAwDAQACAAMAAAAQAM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhQVFxgf/aAAgBAQABPxAsFHPkx2qs9ZBUaOXZuCEM+7//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d1626bb5ca66c8fcd30295136bd95e6/e4706/QNAP_FullHD-as-HD.avif 230w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/d1af7/QNAP_FullHD-as-HD.avif 460w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/23ddf/QNAP_FullHD-as-HD.avif 604w"],
              "sizes": "(max-width: 604px) 100vw, 604px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d1626bb5ca66c8fcd30295136bd95e6/a0b58/QNAP_FullHD-as-HD.webp 230w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/bc10c/QNAP_FullHD-as-HD.webp 460w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/059a8/QNAP_FullHD-as-HD.webp 604w"],
              "sizes": "(max-width: 604px) 100vw, 604px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d1626bb5ca66c8fcd30295136bd95e6/e83b4/QNAP_FullHD-as-HD.jpg 230w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/e41a8/QNAP_FullHD-as-HD.jpg 460w", "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/efa6e/QNAP_FullHD-as-HD.jpg 604w"],
              "sizes": "(max-width: 604px) 100vw, 604px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d1626bb5ca66c8fcd30295136bd95e6/efa6e/QNAP_FullHD-as-HD.jpg",
              "alt": "Full HD as HD Camera",
              "title": "Full HD as HD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Due to the lack of native support at Synology Surveillance Station, the camera has been integrated with ONVIF so far.
The image could thus be transferred / recorded with the highest quality settings (connection via LAN).
Only the motion detection with integration of the PIR was not possible - therefore there were exactly as many false alarms, as with all other used cameras (which cost however with the same / better picture quality only a quarter).
The parallel recordings on the camera's internal SD card (using the PIR) have shown the potential I had hoped for.
There were hardly any false alarms - just in case of rain and snow, a simple motion detection shows almost endless alarm - with the PIR everything is perfect.`}</p>
    <p>{`With the native support available at Surveillance Station since last week, hope came up.`}</p>
    <p>{`The first problem came right after switching to the native driver - the transmission of the stream was no longer possible.
The H.264 stream was no longer stable transferable - before at ONVIF without problems.
The stream always stopped after the first seconds.`}</p>
    <p>{`As a cause, I have then set the setting "transmission protocol" under "RTSP and RTP setting" in the Surveillance Station.
This is by default on "Automatic", after conversion to "TCP" everything was perfect again.
The automatic seems here (at least in my network environment) to choose the wrong setting.`}</p>
    <p>{`But now for motion detection - the native driver can now evaluate PIR and camera motion detection as an event in the Surveillance Station.
After the first few days I can say - it finally works - with a few limitations.`}</p>
    <p>{`If there is no pairing with the detection areas in the camera, the PIR will also be reported as a PIR event to the Surveillance Station.
As soon as a coupling with the areas is activated, only the event "movement" is reported.
That's no problem - but you have to find out first.
Basically, this works fine - but here comes the restriction: It is only evaluated area 1 and reported to the Surveillance Station.
If detected in ranges 2 through 4, no event will be sent to the Surveillance Station.`}</p>
    <p>{`In my case, I can live with this restriction - definitely much better than before.
Only the last days with continuous rain and snow have shown - not a single false alarm.
(While the other cameras have almost recorded in continuous loop).`}</p>
    <p>{`I can now fully recommend the camera in combination with the Synology Surveillance Station.
I will consider if I will not exchange more of my cameras (without PIR) for a 9008HD.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c4451fdcdd32c05327eb700fa14deccd/6e9b1/image_2356.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdmK1Bxg/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxAx/9oACAEBAAEFApY9iuOGEnP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAABBQAAAAAAAAAAAAAAAAACABEgIXH/2gAIAQEABj8CTCNZH//EABgQAQEBAQEAAAAAAAAAAAAAAAEAITER/9oACAEBAAE/IQtivXd3tK9SSNi2OX//2gAMAwEAAgADAAAAENAP/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQMBAT8Qh//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/EKf/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMZGh/9oACAEBAAE/EKtv3eMK9P1imoBSSO4hwOIruyvs3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4451fdcdd32c05327eb700fa14deccd/e4706/image_2356.avif 230w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/d1af7/image_2356.avif 460w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/7f308/image_2356.avif 920w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/20fe0/image_2356.avif 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c4451fdcdd32c05327eb700fa14deccd/a0b58/image_2356.webp 230w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/bc10c/image_2356.webp 460w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/966d8/image_2356.webp 920w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/3697b/image_2356.webp 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c4451fdcdd32c05327eb700fa14deccd/e83b4/image_2356.jpg 230w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/e41a8/image_2356.jpg 460w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/4d836/image_2356.jpg 920w", "/en/static/c4451fdcdd32c05327eb700fa14deccd/6e9b1/image_2356.jpg 971w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c4451fdcdd32c05327eb700fa14deccd/4d836/image_2356.jpg",
              "alt": "Full HD as HD Camera",
              "title": "Full HD as HD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2499441f9305b76da2bc8f15106352d/c30b1/image_2357.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHsHUiozP8A/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEREhAhMf/aAAgBAQABBQJ6LLESVQuf/8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Bh//EABYRAAMAAAAAAAAAAAAAAAAAABAhQf/aAAgBAgEBPwFwf//EABYQAAMAAAAAAAAAAAAAAAAAABAgMf/aAAgBAQAGPwJIP//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESExcf/aAAgBAQABPyFc6t9IdNkejZx8T5v/2gAMAwEAAgADAAAAEFAf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxAobLHSf//EABcRAQEBAQAAAAAAAAAAAAAAAAEAMRH/2gAIAQIBAT8QRuLibf/EABoQAQADAQEBAAAAAAAAAAAAAAEAETFxIWH/2gAIAQEAAT8QIWlvYHlD4QCGJcwqmXAJPAjPOf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2499441f9305b76da2bc8f15106352d/e4706/image_2357.avif 230w", "/en/static/a2499441f9305b76da2bc8f15106352d/d1af7/image_2357.avif 460w", "/en/static/a2499441f9305b76da2bc8f15106352d/7f308/image_2357.avif 920w", "/en/static/a2499441f9305b76da2bc8f15106352d/651ce/image_2357.avif 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2499441f9305b76da2bc8f15106352d/a0b58/image_2357.webp 230w", "/en/static/a2499441f9305b76da2bc8f15106352d/bc10c/image_2357.webp 460w", "/en/static/a2499441f9305b76da2bc8f15106352d/966d8/image_2357.webp 920w", "/en/static/a2499441f9305b76da2bc8f15106352d/aa5f8/image_2357.webp 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2499441f9305b76da2bc8f15106352d/e83b4/image_2357.jpg 230w", "/en/static/a2499441f9305b76da2bc8f15106352d/e41a8/image_2357.jpg 460w", "/en/static/a2499441f9305b76da2bc8f15106352d/4d836/image_2357.jpg 920w", "/en/static/a2499441f9305b76da2bc8f15106352d/c30b1/image_2357.jpg 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2499441f9305b76da2bc8f15106352d/4d836/image_2357.jpg",
              "alt": "Full HD as HD Camera",
              "title": "Full HD as HD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27b633d5761657fa2448d2d4aa330b26/ec7ce/image_2355.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aBR/8QAFxABAAMAAAAAAAAAAAAAAAAAEAERMf/aAAgBAQABBQKmcP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAAEFAQAAAAAAAAAAAAAAAAEAEBEhMWH/2gAIAQEAAT8hsNIUKOtDG//aAAwDAQACAAMAAAAQw8//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxCI/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAAIDAAMBAAAAAAAAAAAAAAABESExYXGRof/aAAgBAQABPxBsiLk0/gorWLmOzVhnb9Fh/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27b633d5761657fa2448d2d4aa330b26/e4706/image_2355.avif 230w", "/en/static/27b633d5761657fa2448d2d4aa330b26/d1af7/image_2355.avif 460w", "/en/static/27b633d5761657fa2448d2d4aa330b26/7f308/image_2355.avif 920w", "/en/static/27b633d5761657fa2448d2d4aa330b26/006d3/image_2355.avif 970w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27b633d5761657fa2448d2d4aa330b26/a0b58/image_2355.webp 230w", "/en/static/27b633d5761657fa2448d2d4aa330b26/bc10c/image_2355.webp 460w", "/en/static/27b633d5761657fa2448d2d4aa330b26/966d8/image_2355.webp 920w", "/en/static/27b633d5761657fa2448d2d4aa330b26/dbca2/image_2355.webp 970w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27b633d5761657fa2448d2d4aa330b26/e83b4/image_2355.jpg 230w", "/en/static/27b633d5761657fa2448d2d4aa330b26/e41a8/image_2355.jpg 460w", "/en/static/27b633d5761657fa2448d2d4aa330b26/4d836/image_2355.jpg 920w", "/en/static/27b633d5761657fa2448d2d4aa330b26/ec7ce/image_2355.jpg 970w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27b633d5761657fa2448d2d4aa330b26/4d836/image_2355.jpg",
              "alt": "Full HD as HD Camera",
              "title": "Full HD as HD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      